import { node } from 'prop-types';
import React, { createContext, useCallback, useState } from 'react';

const ContentCounterContext = createContext({ itemsCount: 0, dividersCount: 0, increaseItemsCounterBy: () => {}, increaseDividersCounterBy: () => {} });

export function ContentCounterProvider({ children }) {
  const [itemsCount, setItemsCount] = useState(0);
  const [dividersCount, setDividersCount] = useState(0);

  const increaseItemsCounterBy = useCallback(amount => {
    setItemsCount(prev => prev + amount);
  }, []);

  const increaseDividersCounterBy = useCallback(amount => {
    setDividersCount(prev => prev + amount);
  }, []);

  return <ContentCounterContext.Provider value={{ increaseItemsCounterBy, increaseDividersCounterBy, itemsCount, dividersCount }}>{children}</ContentCounterContext.Provider>;
}

ContentCounterProvider.propTypes = {
  children: node.isRequired,
};

export default ContentCounterContext;
