/* eslint-disable react/no-unused-state */
import React from 'react';
import debounce from 'lodash/debounce';
import { bool } from 'prop-types';
import getDisplayName from './utils/display-name';
import { COMPACT_DOCK_HEIGHT } from '../modules/player/components/dock';

const withDimensions = Component => {
  const WrappedComponent = class extends React.Component {
    // eslint-disable-next-line react/static-property-placement
    static displayName = `withDimensions(${getDisplayName(Component)})`;

    constructor(props) {
      super(props);

      this.state = {
        height: 0,
        width: 0,
      };

      this.containerRef = React.createRef();
      this.debounceResize = debounce(this.resize, 250);
    }

    componentDidMount() {
      this.resize();
      window.addEventListener('resize', this.debounceResize);
    }

    componentDidUpdate(prevProps) {
      const { isMobileHeight } = this.props;

      if (prevProps.isMobileHeight !== isMobileHeight) {
        if (this.containerRef.current) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState(this.calculateDimensions(this.containerRef.current));
        }
      }
    }

    componentWillUnmount() {
      this.unmounting = true;
      window.removeEventListener('resize', this.debounceResize);
      this.debounceResize.cancel();
    }

    resize = () => {
      if (this.unmounting) return;

      if (window.pageYOffset < 0) window.scrollTo(0, 0);

      const element = this.containerRef.current;
      if (element) {
        this.setState(this.calculateDimensions(element));
      }
    };

    calculateDimensions = element => {
      const { isMobileHeight } = this.props;

      return {
        width: element.clientWidth,
        height: element.clientHeight - (isMobileHeight ? COMPACT_DOCK_HEIGHT : 0),
      };
    };

    render() {
      return (
        <div ref={this.containerRef} className="pbb-book--container full-height" style={{ flexGrow: 1, minWidth: 0 }}>
          <Component {...this.props} dimensions={this.state} />
        </div>
      );
    }
  };

  WrappedComponent.propTypes = {
    isMobileHeight: bool.isRequired,
  };

  return WrappedComponent;
};

export default withDimensions;
