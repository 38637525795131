import debounce from 'lodash/debounce';

import {
  DISABLE_ZOOM_TO_FIT_MODE,
  HIDE_PARTIAL_ANSWER,
  HIDE_STEPPER_ANSWER,
  REVEAL_PARTIAL_ANSWER,
  REVEAL_STEPPER_ANSWER,
  SET_CURRENTPAGE,
  SET_IS_MOBILE_WIDTH,
  SET_VIEWMODE,
  SET_ZOOM_LEVEL,
  TOC_NODE_CLICKED,
  ZOOM_IN,
  ZOOM_OUT,
  ZOOM_TO_FIT,
} from './actionNames';

import { retrieveMedialinksForCurrentPages } from './medialinks';
import { retrieveNotesForCurrentPages } from './notes';
import { retrievePublishedSlideSetsForCurrentPages } from './slideSets';
import { setCurrentTool } from './tools';

import { getCurrentDigibook, getCurrentModuleId, getTotalPagesForDigibook } from '../selectors/digibooks';
import { canGotoNextPage, canGotoPreviousPage, getCurrentPageNumber, getViewMode } from '../selectors/navigation';
import { getAmountOfPagesToShow, shouldRenderSpread } from '../selectors/rendering';

import { getFeatureToggleFor } from '../utils/feature-toggle';
import isOdd from '../utils/isOdd';
import { retrieveWhitepagesForCurrentPages } from './whitepages';

import FeatureToggle from '../enums/feature-toggle';
import PlayerMode from '../enums/playerMode';
import Tools from '../enums/tools';

import { getPlayerMode } from '../selectors/player';
import { getIsSlideSetsAccessEnabled } from '../selectors/slideSets';
import { isTeacherAlike } from '../selectors/user';
import api from '../services/api';
import { setPlayerMode } from './playerMode';

export function setViewMode(viewMode) {
  return {
    type: SET_VIEWMODE,
    payload: {
      viewMode,
    },
  };
}

export function setIsMobileWidth(isMobileWidth) {
  return {
    type: SET_IS_MOBILE_WIDTH,
    payload: {
      isMobileWidth,
    },
  };
}

const correctPageNumber = (digibook, totalPages) => pageNumber => {
  if (!pageNumber || Number.isNaN(pageNumber)) return digibook.cover ? 0 : 1;
  if (pageNumber <= 0) return digibook.cover ? 0 : 1;
  if (pageNumber > totalPages) return digibook.backCover ? totalPages + 1 : totalPages;
  return pageNumber;
};

export function setCurrentPage(pageNumber) {
  return (dispatch, getState) => {
    const state = getState();
    const currentPage = getCurrentPageNumber(state);
    const digibook = getCurrentDigibook(state);
    const totalPages = getTotalPagesForDigibook(state);
    const getNextPageForBook = correctPageNumber(digibook, totalPages);
    const playerMode = getPlayerMode(state);
    const isSlideSetsAccessEnabled = getIsSlideSetsAccessEnabled(state);
    const isTeacher = isTeacherAlike(state);

    if (playerMode === PlayerMode.WHITEPAGE) {
      dispatch(setPlayerMode(PlayerMode.BOOK));
    }

    if (currentPage !== pageNumber) {
      dispatch(setCurrentTool(Tools.POINTER));
      dispatch({
        type: SET_CURRENTPAGE,
        payload: {
          pageNumber: getNextPageForBook(pageNumber),
        },
      });

      dispatch(retrieveMedialinksForCurrentPages());

      if (getFeatureToggleFor(FeatureToggle.SLIDE_SETS) && isSlideSetsAccessEnabled && isTeacher) {
        dispatch(retrievePublishedSlideSetsForCurrentPages());
      }

      if (getFeatureToggleFor(FeatureToggle.NOTES)) {
        dispatch(retrieveNotesForCurrentPages());
      }

      if (getFeatureToggleFor(FeatureToggle.WHITEPAGES)) {
        dispatch(retrieveWhitepagesForCurrentPages());
      }
    }
  };
}

export function tocNodeClicked(node) {
  return async dispatch => {
    if (node.fromPage !== undefined) {
      await dispatch(setCurrentPage(node.fromPage));

      dispatch({
        type: TOC_NODE_CLICKED,
        payload: {
          node,
        },
      });
    }
  };
}

/**
 * @param {{ top: Number, left: Number, width: Number, height: Number }} shape
 * @param {Number[]} pages
 * @param {Number} totalPages The total pages for the digibook.
 */
export const revealAnswerAreaFor = (shape, pages, totalPages) => ({
  type: REVEAL_PARTIAL_ANSWER,
  payload: {
    pages,
    totalPages,
    shape,
  },
});

/**
 * @param {{ top: Number, left: Number, width: Number, height: Number }} shape
 * @param {Number[]} pages
 * @param {Number} totalPages The total pages for the digibook.
 */
export const hideAnswerAreaFor = (shape, pages, totalPages) => ({
  type: HIDE_PARTIAL_ANSWER,
  payload: {
    pages,
    totalPages,
    shape,
  },
});

export const revealStepperAnswer = (set, index) => ({
  type: REVEAL_STEPPER_ANSWER,
  payload: {
    set,
    index,
  },
});

export const hideStepperAnswer = (set, index) => ({
  type: HIDE_STEPPER_ANSWER,
  payload: {
    set,
    index,
  },
});

export const zoomIn = () => dispatch => {
  dispatch(setCurrentTool(Tools.POINTER));
  dispatch({ type: ZOOM_IN });
};

export const zoomOut = () => dispatch => {
  dispatch(setCurrentTool(Tools.POINTER));
  dispatch({ type: ZOOM_OUT });
};

export const zoomToFit = () => dispatch => {
  dispatch(setCurrentTool(Tools.POINTER));
  dispatch({ type: ZOOM_TO_FIT });
};

export const disableZoomToFitMode = () => ({
  type: DISABLE_ZOOM_TO_FIT_MODE,
});

export const setZoomLevel = zoomLevel => ({
  type: SET_ZOOM_LEVEL,
  payload: { zoomLevel },
});

const saveViewMode = debounce((moduleId, viewMode) => api.put(`/studio/user/modules/${moduleId}/codex-player-settings/player-view-mode`, { playerViewMode: viewMode }), 250);

export function changeViewMode(newViewMode) {
  return (dispatch, getState) => {
    const currentViewMode = getViewMode(getState());
    if (currentViewMode === newViewMode) return Promise.resolve();

    const currentModuleId = getCurrentModuleId(getState());

    dispatch(setCurrentTool(Tools.POINTER));
    dispatch(setViewMode(newViewMode));

    return saveViewMode(currentModuleId, newViewMode);
  };
}

export function gotoNextPage() {
  return (dispatch, getState) => {
    const state = getState();
    const currentPageNumber = getCurrentPageNumber(state);
    const amountOfPagesShown = getAmountOfPagesToShow(state);

    if (canGotoNextPage(state)) {
      const totalPages = getTotalPagesForDigibook(state);
      if (isOdd(currentPageNumber) && amountOfPagesShown === 2) return dispatch(setCurrentPage(currentPageNumber + 1));
      if (currentPageNumber === totalPages) return dispatch(setCurrentPage(currentPageNumber + 1));
      return dispatch(setCurrentPage(currentPageNumber + amountOfPagesShown));
    }
    return null;
  };
}

export function gotoPreviousPage() {
  return (dispatch, getState) => {
    const state = getState();
    const currentPageNumber = getCurrentPageNumber(state);
    const amountOfPagesShown = getAmountOfPagesToShow(state);

    if (canGotoPreviousPage(state)) {
      if (shouldRenderSpread(state)) {
        // first page and cover are page mode, actual spreads start at page 2
        const totalPages = getTotalPagesForDigibook(state);
        const hasEvenAmountOfPages = totalPages % 2 === 0;
        const isBackCover = currentPageNumber === totalPages + 1;
        if (isBackCover && hasEvenAmountOfPages) return dispatch(setCurrentPage(currentPageNumber - 1));
        if (isOdd(currentPageNumber) && amountOfPagesShown === 2) return dispatch(setCurrentPage(currentPageNumber - 3));
        if (currentPageNumber > 2) return dispatch(setCurrentPage(currentPageNumber - 2));
      }
      return dispatch(setCurrentPage(currentPageNumber - 1));
    }
    return null;
  };
}
