import SolutionOnIcon from '@pelckmans/business-components/icons/LightBulbWithCheckMark';
import SolutionOffIcon from '@pelckmans/business-components/icons/LightBulbWithCross';
import { arrayOf, number, shape } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { HIDE_ALL_ANSWERS, SHOW_ALL_ANSWERS } from '../../../../../../../actions/actionNames';
import { setCurrentTool } from '../../../../../../../actions/tools';
import Tools from '../../../../../../../enums/tools';
import { getAnswerLayerPagesToShow, getPageNumbersToShow, shouldRenderSolutionsPage } from '../../../../../../../selectors/rendering';

function SolutionsToggleButton({ digibook }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const renderSolutionsPage = useSelector(shouldRenderSolutionsPage);
  const currentAnswerPages = useSelector(getAnswerLayerPagesToShow);
  const currentPages = useSelector(getPageNumbersToShow);

  const revealableCurrentPages = useMemo(() => currentPages.filter(page => digibook.allowedRanges?.some(range => range.from <= page && range.to >= page)), [
    currentPages,
    digibook.allowedRanges,
  ]);

  const anyAnswerPageOnBookRevealed = !renderSolutionsPage && currentAnswerPages.filter(x => x !== null).length > 0;

  const handleToggleSolutions = () => {
    if (anyAnswerPageOnBookRevealed) {
      dispatch(setCurrentTool(Tools.POINTER));
      dispatch({
        type: HIDE_ALL_ANSWERS,
        payload: {
          pageNumbers: revealableCurrentPages,
        },
      });
    } else {
      dispatch(setCurrentTool(Tools.POINTER));
      dispatch({
        type: SHOW_ALL_ANSWERS,
        payload: {
          pageNumbers: revealableCurrentPages,
          totalPages: digibook.totalPages,
        },
      });
    }
  };

  return (
    <button
      type="button"
      disabled={revealableCurrentPages.length === 0 || renderSolutionsPage}
      onClick={handleToggleSolutions}
      title={
        anyAnswerPageOnBookRevealed
          ? t('dock.items.solutions.SolutionsPanel.buttons.solutionsToggle.hide.tooltip')
          : t('dock.items.solutions.SolutionsPanel.buttons.solutionsToggle.reveal.tooltip')
      }
    >
      {anyAnswerPageOnBookRevealed ? <SolutionOffIcon /> : <SolutionOnIcon />}
    </button>
  );
}

SolutionsToggleButton.propTypes = {
  digibook: shape({
    allowedRanges: arrayOf(
      shape({
        from: number.isRequired,
        to: number.isRequired,
      }),
    ),
    totalPages: number.isRequired,
  }),
};

export default SolutionsToggleButton;
