import { ActionMenu, MenuItem } from '@pelckmans/business-components/components/action-menu';
import BookScrollModeIcon from '@pelckmans/business-components/icons/BookScrollMode';
import BookSinglePageModeIcon from '@pelckmans/business-components/icons/BookSinglePageMode';
import BookSpreadModeIcon from '@pelckmans/business-components/icons/BookSpreadMode';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { changeViewMode } from '../../../../../actions/navigation';
import FeatureToggle from '../../../../../enums/feature-toggle';
import PlayerMode from '../../../../../enums/playerMode';
import ViewMode from '../../../../../enums/ViewMode';
import { isFeatureEnabled } from '../../../../../selectors/featureToggle';
import { getViewMode } from '../../../../../selectors/navigation';
import { getIsSolutionsPageVisible, getPlayerMode } from '../../../../../selectors/player';
import { initialState as NavigationInitialState } from '../../../../../reducers/navigationReducer';

const DEFAULT_ICONS_BY_VIEW_MODE = Object.freeze({
  [ViewMode.PAGE]: BookSinglePageModeIcon,
  [ViewMode.SPREAD]: BookSpreadModeIcon,
});

function ViewModesButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const viewMode = useSelector(getViewMode);
  const isScrollViewModeEnabled = useSelector(state => isFeatureEnabled(state, FeatureToggle.CODEX_SCROLL_VIEW));
  const playerMode = useSelector(getPlayerMode);
  const isSolutionsPageVisible = useSelector(getIsSolutionsPageVisible);

  const isActionButtonDisabled = playerMode === PlayerMode.WHITEPAGE || isSolutionsPageVisible;
  const enabledIconsByViewMode = { ...DEFAULT_ICONS_BY_VIEW_MODE, ...(isScrollViewModeEnabled ? { [ViewMode.SCROLL]: BookScrollModeIcon } : {}) };

  const ViewModeIcon = enabledIconsByViewMode[viewMode] || enabledIconsByViewMode[NavigationInitialState.viewMode];

  useEffect(() => {
    // fallback for saved user codex settings is scroll and feature scroll view is not enabled
    if (viewMode === ViewMode.SCROLL && !isScrollViewModeEnabled) {
      dispatch(changeViewMode(NavigationInitialState.viewMode));
    }
  }, [dispatch, isScrollViewModeEnabled, viewMode]);

  return (
    <ActionMenu
      title="viewModeMenu"
      menuClassName="dock-flyout-menu"
      action={
        <button type="button" title={t('dock.buttons.viewModes.tooltip')} disabled={isActionButtonDisabled}>
          <ViewModeIcon />
        </button>
      }
      setDownOverflow
      boundingBoxPadding="10"
      portal
    >
      {Object.entries(enabledIconsByViewMode).map(([mode, Icon]) => (
        <MenuItem
          className={classNames('dock-flyout-menu__item', { 'dock-flyout-menu__item--active': viewMode === mode })}
          key={mode}
          onClick={() => dispatch(changeViewMode(mode))}
          title={t(`dock.actionMenu.viewModes.${mode}`)}
        >
          <Icon />
          {t(`dock.actionMenu.viewModes.${mode}`)}
        </MenuItem>
      ))}
    </ActionMenu>
  );
}

export default ViewModesButton;
