import { string } from 'prop-types';
import React from 'react';

function ModuleLogo({ logo }) {
  return <span data-testid="module-logo" className="module-logo" style={{ backgroundImage: logo && `url('${logo}')` }} />;
}

ModuleLogo.propTypes = {
  logo: string,
};

export default ModuleLogo;
