import ZoomInIcon from '@pelckmans/business-components/icons/ZoomIn';
import ZoomMaskIcon from '@pelckmans/business-components/icons/ZoomMask';
import ZoomOutIcon from '@pelckmans/business-components/icons/ZoomOut';
import classNames from 'classnames';
import { bool } from 'prop-types';
import React, { useContext, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { zoomIn, zoomOut } from '../../../../../../actions/navigation';
import { setCurrentTool } from '../../../../../../actions/tools';
import Tools from '../../../../../../enums/tools';
import ViewModes from '../../../../../../enums/ViewMode';
import ZoomLevel from '../../../../../../enums/zoomLevel';
import { getViewMode, getZoomLevel } from '../../../../../../selectors/navigation';
import { getCurrentTool } from '../../../../../../selectors/tools';
import ContentCounterContext from '../../../../context/ContentCounterContext';
import ZoomToFitButton from './ZoomToFitButton';

function ZoomButtons({ isMobileWidth }) {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const zoomLevel = useSelector(getZoomLevel);
  const isZoomSelectionMode = useSelector(state => getCurrentTool(state) === Tools.ZOOM_SELECT);
  const isScrollViewMode = useSelector(getViewMode) === ViewModes.SCROLL;

  const { increaseItemsCounterBy } = useContext(ContentCounterContext);
  useLayoutEffect(() => {
    increaseItemsCounterBy(4);
  }, [increaseItemsCounterBy]);

  if (isMobileWidth) return <ZoomToFitButton />;

  return (
    <>
      <button
        type="button"
        className="pbb-sidebar__zoom-plus"
        onClick={() => dispatch(zoomIn())}
        disabled={zoomLevel >= ZoomLevel.MAX_ZOOM_LEVEL || isScrollViewMode}
        title={t('dock.buttons.zoomIn.tooltip')}
      >
        <ZoomInIcon />
      </button>
      <button
        type="button"
        className="pbb-sidebar__zoom-min"
        onClick={() => dispatch(zoomOut())}
        disabled={zoomLevel <= ZoomLevel.MIN_ZOOM_LEVEL || isScrollViewMode}
        title={t('dock.buttons.zoomOut.tooltip')}
      >
        <ZoomOutIcon />
      </button>
      <button
        type="button"
        className={classNames('pbb-sidebar__zoom-drag', {
          'pbb-active': isZoomSelectionMode,
        })}
        onClick={() => dispatch(setCurrentTool(isZoomSelectionMode ? Tools.POINTER : Tools.ZOOM_SELECT))}
        title={t('dock.buttons.zoomDrag.tooltip')}
        disabled={isScrollViewMode}
      >
        <ZoomMaskIcon />
      </button>
      <ZoomToFitButton disabled={isScrollViewMode} />
    </>
  );
}

ZoomButtons.propTypes = {
  isMobileWidth: bool.isRequired,
};

export default ZoomButtons;
