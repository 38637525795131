import classNames from 'classnames';
import { arrayOf, bool, number, shape } from 'prop-types';
import React, { useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SolutionMaskIcon from '@pelckmans/business-components/icons/LightBulbMask';
import SolutionUnmaskIcon from '@pelckmans/business-components/icons/LightBulbUnmask';
import SolutionStepperIcon from '@pelckmans/business-components/icons/LightBulbWithArrowRight';
import SolutionPageIcon from '@pelckmans/business-components/icons/LightBulbWithCheckMarkOnPage';

import { HIDE_ALL_ANSWERS, TOGGLE_ANSWER_HIDE, TOGGLE_ANSWER_REVEAL, TOGGLE_ANSWER_STEPPER, TOGGLE_SOLUTIONS_PAGE, ZOOM_TO_FIT } from '../../../../../../actions/actionNames';
import { setCurrentPage } from '../../../../../../actions/navigation';
import { setCurrentTool } from '../../../../../../actions/tools';
import Tools from '../../../../../../enums/tools';
import ViewMode from '../../../../../../enums/ViewMode';
import { getCurrentPageNumber, getViewMode } from '../../../../../../selectors/navigation';
import { getPageNumbersToShow, shouldRenderSolutionsPage } from '../../../../../../selectors/rendering';
import { getAnswerStepperMode, getCurrentTool } from '../../../../../../selectors/tools';
import ContentCounterContext from '../../../../context/ContentCounterContext';
import SolutionsToggleButton from './components/SolutionsToggleButton';

export default function SolutionsPanel({ digibook, isMobileWidth }) {
  const { teacherFeaturesEnabled, allowedRanges } = digibook;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const currentPage = useSelector(getCurrentPageNumber);
  const currentPages = useSelector(getPageNumbersToShow);
  const currentTool = useSelector(getCurrentTool);
  const answerStepperMode = useSelector(getAnswerStepperMode);
  const renderSolutionsPage = useSelector(shouldRenderSolutionsPage);
  const viewMode = useSelector(getViewMode);

  const { increaseItemsCounterBy } = useContext(ContentCounterContext);
  useLayoutEffect(() => {
    if (!teacherFeaturesEnabled) {
      increaseItemsCounterBy(2);
    } else {
      increaseItemsCounterBy(5);
    }

    return () => {
      if (!teacherFeaturesEnabled) {
        increaseItemsCounterBy(-2);
      } else {
        increaseItemsCounterBy(-5);
      }
    };
  }, [increaseItemsCounterBy, teacherFeaturesEnabled]);

  const revealableCurrentPages = useMemo(() => currentPages.filter(page => allowedRanges?.some(range => range.from <= page && range.to >= page)), [currentPages, allowedRanges]);

  const handleToggleSolutionsPage = () => {
    dispatch(setCurrentTool(Tools.POINTER));
    dispatch({ type: ZOOM_TO_FIT });

    if (currentPage !== currentPages[0]) dispatch(setCurrentPage(currentPages[0]));

    if (answerStepperMode) dispatch({ type: TOGGLE_ANSWER_STEPPER });

    dispatch({
      type: HIDE_ALL_ANSWERS,
      payload: {
        pageNumbers: revealableCurrentPages,
      },
    });

    dispatch({ type: TOGGLE_SOLUTIONS_PAGE });
  };

  useEffect(() => {
    // when navigating from a page with the solutions page visible to a page without the solutions page visible
    // we need to toggle the solutions page visibility again
    if (renderSolutionsPage && revealableCurrentPages.length === 0) {
      dispatch({ type: TOGGLE_SOLUTIONS_PAGE });
    }
  }, [dispatch, renderSolutionsPage, revealableCurrentPages.length]);

  if (isMobileWidth) return <SolutionsToggleButton digibook={digibook} />;

  return (
    <>
      <SolutionsToggleButton digibook={digibook} />

      {teacherFeaturesEnabled && (
        <>
          <button
            type="button"
            disabled={revealableCurrentPages.length === 0 || renderSolutionsPage}
            className={classNames({ 'pbb-active': currentTool === Tools.ANSWER_REVEAL })}
            onClick={() => dispatch({ type: TOGGLE_ANSWER_REVEAL })}
            title={t('dock.items.solutions.buttons.answerLayerUnmaskButton.tooltip')}
          >
            <SolutionUnmaskIcon />
          </button>
          <button
            type="button"
            disabled={revealableCurrentPages.length === 0 || renderSolutionsPage}
            className={classNames({ 'pbb-active': currentTool === Tools.ANSWER_HIDE })}
            onClick={() => dispatch({ type: TOGGLE_ANSWER_HIDE })}
            title={t('dock.items.solutions.buttons.answerLayerMaskButton.tooltip')}
          >
            <SolutionMaskIcon />
          </button>
          <button
            type="button"
            disabled={revealableCurrentPages.length === 0 || renderSolutionsPage}
            className={classNames({ 'pbb-active': answerStepperMode })}
            onClick={() => dispatch({ type: TOGGLE_ANSWER_STEPPER })}
            title={t('dock.items.solutions.buttons.answerStepperToggleButton.tooltip')}
          >
            <SolutionStepperIcon />
          </button>
        </>
      )}

      <button
        type="button"
        disabled={revealableCurrentPages.length === 0 || viewMode === ViewMode.SCROLL}
        className={classNames({ 'pbb-active': renderSolutionsPage })}
        onClick={handleToggleSolutionsPage}
        title={
          renderSolutionsPage
            ? t('dock.items.solutions.SolutionsPanel.buttons.solutionsPageToggle.hide.tooltip')
            : t('dock.items.solutions.SolutionsPanel.buttons.solutionsPageToggle.reveal.tooltip')
        }
      >
        <SolutionPageIcon />
      </button>
    </>
  );
}

SolutionsPanel.propTypes = {
  digibook: shape({
    teacherFeaturesEnabled: bool.isRequired,
    allowedRanges: arrayOf(
      shape({
        from: number.isRequired,
        to: number.isRequired,
      }),
    ),
    totalPages: number.isRequired,
  }).isRequired,
  isMobileWidth: bool.isRequired,
};
