/**
 * This helper function is a fallback for window.open(url, '_blank') for safari in case the popup blocker is not disabled and onClick handler is async.;
 * @param {string} url - The url to open in a new tab
 * @param {string} rel - The rel attribute for the anchor tag
 */
export function openNewTabFallback(url, rel) {
  if (!url) return;

  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.href = url;
  a.target = '_blank';
  a.rel = rel;
  a.click();
  document.body.removeChild(a);
}
