import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { func, string, shape, oneOfType, number } from 'prop-types';
import { useSelector } from 'react-redux';
import ColorPicker from '@pelckmans/business-components/components/advanced-color-picker';
import Sizes from '../../../../../enums/sizes';
import Tools from '../../../../../enums/tools';
import { EMPTY_OBJECT } from '../../../../../utils/stable-default-props';
import { getTools } from '../../../../../selectors/tools';
import { colorObjects } from '../../../../../enums/colors';
import CustomColorContext from '../../../../../contexts/CustomColorContext';
import { rgbaToRgb } from '../../../../../utils/colors';

const ToolOptions = ({ variant, onColorClick, onSizeClick, currentOptions = EMPTY_OBJECT }) => {
  const { saveCustomColor, customColors } = useContext(CustomColorContext);
  const [t] = useTranslation();
  const tools = useSelector(getTools);
  const tool = tools[variant];

  const translations = {
    colorPicker: {
      customSlot: {
        title: t('colorPicker.customSlot.title'),
      },
      buttons: {
        cancel: t('colorPicker.buttons.cancel'),
        confirm: t('colorPicker.buttons.confirm'),
        edit: t('colorPicker.buttons.edit'),
      },
    },
    colors: {
      green: t('colors.green'),
      yellow: t('colors.yellow'),
      orange: t('colors.orange'),
      red: t('colors.red'),
      blue: t('colors.blue'),
      purple: t('colors.purple'),
      black: t('colors.black'),
      grey: t('colors.grey'),
      transparent: t('colors.transparent'),
      white: t('colors.white'),
    },
  };

  return (
    <>
      {variant !== Tools.TEXT_MARKER && (
        <>
          <div className={classNames('pbb-menu__sizes', `pbb-menu__sizes--${variant}`)}>
            {Object.values(Sizes).map(size => (
              <button
                key={size}
                onClick={() => onSizeClick(size)}
                type="button"
                title={t(`sizes.tool.${size}`)}
                className={classNames(`size--${size}`, 'pbb-sidebar__button', {
                  'pbb-sidebar__button--active': currentOptions.size === size,
                })}
              >
                <span className="indicator" style={{ backgroundColor: rgbaToRgb(tool?.color?.color, 0.75) }} />
              </button>
            ))}
          </div>
          <div className="divider-line" />
        </>
      )}
      {tool?.color && (
        <ColorPicker
          activeColor={tool?.color}
          defaultColors={Object.values(colorObjects)}
          customColors={customColors[variant]}
          onColorSelect={onColorClick}
          saveCustomColor={saveCustomColor}
          translations={translations}
          tool={variant}
        />
      )}
    </>
  );
};

ToolOptions.propTypes = {
  variant: string.isRequired,
  onColorClick: func.isRequired,
  onSizeClick: func.isRequired,
  currentOptions: shape({
    color: shape({
      id: oneOfType([string, number]).isRequired,
      color: string.isRequired,
      order: number.isRequired,
    }),
    size: string,
    backgroundColor: string,
  }),
};

export default ToolOptions;
