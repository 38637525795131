import React, { useLayoutEffect } from 'react';

import { func } from 'prop-types';
import NavigationSidebarItem from '../../sidebar/items/navigation';

export default function Navigation({ calculateTotalWidth }) {
  useLayoutEffect(() => {
    calculateTotalWidth(1);

    return () => {
      calculateTotalWidth(0);
    };
  });

  return <NavigationSidebarItem />;
}

Navigation.propTypes = {
  calculateTotalWidth: func.isRequired,
};
