import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { func, string, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PointerIcon from '@pelckmans/business-components/icons/Pointer';
import TextAnnotationIcon from '@pelckmans/business-components/icons/TextAnnotation';
import ToolsEnum from '../../../../../enums/tools';
import { setCurrentMathTool, setCurrentTool } from '../../../../../actions/tools';
import { getCurrentMathTool, getCurrentTool, getTools } from '../../../../../selectors/tools';
import ToolSet from '../tools/tool-set';
import Eraser from '../tools/eraser';
import { getPlayerMode } from '../../../../../selectors/player';
import { getFeatureToggleFor } from '../../../../../utils/feature-toggle';
import FeatureToggle from '../../../../../enums/feature-toggle';
import ContentCounterContext from '../../../context/ContentCounterContext';

const { POINTER, PENCIL, MARKER, TEXT_MARKER, CLASSIC_ERASER, SELECTION_ERASER, RULER, DRAFTING_COMPASS, SET_SQUARE, TEXT_ANNOTATION } = ToolsEnum;

export const Tools = ({ currentTool, tools, setShowMarkings, dispatch, currentMathTool }) => {
  const [t] = useTranslation();

  const drawingTools = [PENCIL, MARKER, TEXT_MARKER];
  const mathTools = [RULER, DRAFTING_COMPASS, SET_SQUARE];

  const [openTool, setOpenTool] = useState(undefined);
  const [openMathTool, setOpenMathTool] = useState(undefined);

  const handleToolClick = tool => {
    if (tool !== POINTER) setShowMarkings(true);

    setOpenTool(openTool === tool ? undefined : tool);
    dispatch(setCurrentTool(tool));

    if (openMathTool) setOpenMathTool(undefined);
    if (currentMathTool && [TEXT_MARKER, TEXT_ANNOTATION].includes(tool)) dispatch(setCurrentMathTool(undefined));
  };

  const handleMathToolClick = mathTool => {
    setOpenMathTool(openMathTool === mathTool ? undefined : mathTool);
    dispatch(setCurrentMathTool(mathTool));

    if (openTool) setOpenTool(undefined);
    if ([TEXT_MARKER, TEXT_ANNOTATION].includes(currentTool)) dispatch(setCurrentTool(POINTER));
  };

  useEffect(() => {
    if (currentTool !== openTool) setOpenTool(undefined);
  }, [currentTool, openTool]);

  useEffect(() => {
    if (currentMathTool !== openMathTool) setOpenMathTool(undefined);
  }, [currentMathTool, openMathTool]);

  const { increaseItemsCounterBy } = useContext(ContentCounterContext);
  useLayoutEffect(() => {
    if (!getFeatureToggleFor(FeatureToggle.MATH_TOOLS)) increaseItemsCounterBy(4);
    else increaseItemsCounterBy(5);
  }, [increaseItemsCounterBy]);

  return (
    <div className="pbb-sidebar__tools">
      <button
        type="button"
        onClick={() => handleToolClick(POINTER)}
        className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active': currentTool === POINTER })}
        title={t('sideBar.tools.defaultTool.tooltip')}
      >
        <PointerIcon className="pbb-sidebar__icon" />
      </button>
      <ToolSet
        isOpen={drawingTools.includes(openTool)}
        currentTool={currentTool}
        onToolClick={handleToolClick}
        tools={drawingTools}
        tooltip={t('toolset.tooltip')}
        menuClassName="pbb-sidebar__menu-drawing"
      />
      <button
        type="button"
        onClick={() => {
          handleToolClick(TEXT_ANNOTATION);
        }}
        title={t('annotationTool.tooltip')}
        className={classNames('pbb-sidebar__button', {
          'pbb-sidebar__button--active': currentTool === TEXT_ANNOTATION,
        })}
      >
        <TextAnnotationIcon className="pbb-sidebar__icon" />
      </button>
      {getFeatureToggleFor(FeatureToggle.MATH_TOOLS) && (
        <ToolSet
          currentTool={currentMathTool}
          isOpen={mathTools.includes(openMathTool)}
          onToolClick={handleMathToolClick}
          renderWithOptions={false}
          tools={mathTools}
          tooltip={t('mathToolset.tooltip')}
        />
      )}
      <Eraser isOpen={[CLASSIC_ERASER, SELECTION_ERASER].includes(openTool)} currentTool={currentTool} options={tools[CLASSIC_ERASER]} onToolClick={handleToolClick} />
    </div>
  );
};

Tools.propTypes = {
  // Own Props
  setShowMarkings: func.isRequired,

  // Connected Props
  dispatch: func.isRequired,
  currentTool: string,
  tools: object.isRequired,
  currentMathTool: string,
};

const mapStateToProps = state => ({
  currentTool: getCurrentTool(state),
  tools: getTools(state),
  playerMode: getPlayerMode(state),
  currentMathTool: getCurrentMathTool(state),
});

export default connect(mapStateToProps)(Tools);
