/* eslint-disable max-classes-per-file */
import { colorObjects } from '../../../../../enums/colors';
import { brushSizes } from '../../../../../enums/sizes';
import tools from '../../../../../enums/tools';

const ANNOTATION_PADDING = 8;

class Element {
  constructor(tag) {
    this.tag = tag;
    this.children = [];
    this.styles = {};
  }

  appendChild(child) {
    this.children.push(child);

    return this;
  }

  setStyle(key, value) {
    if (value) {
      this.styles[key] = key === 'font-size' ? `${value}px` : value;
    }

    return this;
  }

  toString() {
    const children = this.children.map(child => child.toString()).join('');
    const style = Object.entries(this.styles)
      .map(([key, value]) => `${key}: ${value};`)
      .join('');

    const hasStyle = Object.keys(this.styles).length > 0;

    return `<${this.tag}${hasStyle ? ` style="${style}"` : ''}>${children}</${this.tag}>`;
  }
}

class Text extends Element {
  constructor(text) {
    super();
    this.text = text;
  }

  toString() {
    return this.text;
  }
}

class Break extends Element {
  tag = 'br';

  toString() {
    return `<${this.tag}>`;
  }
}

export function migrateOldAnnotation(annotation, spreadWidth, spreadHeight) {
  const elements = annotation.text.split('\n').map(text =>
    new Element('p').appendChild(
      new Element('span')
        .appendChild(text ? new Text(text) : new Break())
        .setStyle('color', colorObjects[annotation.color].color)
        .setStyle('font-size', brushSizes[tools.ANNOTATION][annotation.fontSize])
        .setStyle('line-height', '1.3')
        .setStyle('font-family', 'myriad-pro'),
    ),
  );

  return {
    id: annotation.id,
    top: annotation.top * spreadHeight - ANNOTATION_PADDING,
    left: annotation.left * spreadWidth - ANNOTATION_PADDING,
    width: (annotation.width || 0) * spreadWidth,
    height: annotation.resizeHeight || (annotation.height || 0) * spreadHeight,
    text: elements.map(element => element.toString()).join(''),
    resized: annotation.resized,
    backgroundColor: annotation.backgroundColor,
    isEdited: true,
  };
}
