import React, { useContext } from 'react';
import { func, arrayOf, shape, string, bool } from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
import Module from './module';
import Footer from './footer';
import TocItem from './tocItem';
import { getChapterIdsOfCurrentModule, getTOCNodeById, getExpandedNodes, getActiveNodeToHighlight } from '../../../../../../selectors/toc';
import { tocNodeClicked } from '../../../../../../actions/navigation';
import { buildModuleInPUrl } from '../../../../../../utils/buildModuleInPUrl';
import { getModuleById } from '../../../../../../selectors/module';
import { getTocNodeIdsForSharedSolutions } from '../../../../../../selectors/sharing';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import { PageEventContext } from '../../../../context/PageEventContext';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../../enums/analytics';
import { getModuleForDigibook } from '../../../../../../selectors/digibooks';
import { EMPTY_ARRAY, EMPTY_FUNCTION } from '../../../../../../utils/stable-default-props';
import { openNewTabFallback } from '../../../../../../utils/window';
import useCloseDrawerOnMobile from '../hooks/useCloseDrawerOnMobile';

const SUB_LOCATION_ID = ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL;

export const Toc = ({
  tocnodeIds = EMPTY_ARRAY,
  onTocNodeClicked,
  getNodeInfo = EMPTY_FUNCTION,
  activeNode,
  expandedNodes = EMPTY_ARRAY,
  superModule,
  module,
  digibook,
  tocNodeIdsForSharedSolutions = EMPTY_ARRAY,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const analytics = useAnalytics();
  const { capturePageEvent } = useContext(PageEventContext);

  const isNodeExpanded = nodeId => expandedNodes.map(n => n.id).includes(nodeId);
  const isNodeActive = nodeId => activeNode && nodeId === activeNode.id;

  const handleNodeClickedAndCloseDrawer = useCloseDrawerOnMobile(async clickedNode => {
    if (clickedNode.fromPage !== undefined) {
      await analytics.capture({
        eventType: ANALYTICS_EVENT_TYPES.NODE_ACCESSED,
        objectId: clickedNode.id,
        subLocationId: SUB_LOCATION_ID,
      });

      capturePageEvent(SUB_LOCATION_ID);

      return onTocNodeClicked(clickedNode);
    }

    if (superModule) {
      await analytics.capture({
        eventType: ANALYTICS_EVENT_TYPES.MODULE_ACCESSED,
        objectId: module.id,
        subLocationId: SUB_LOCATION_ID,
      });

      return openNewTabFallback(buildModuleInPUrl(language, superModule, module.slug, clickedNode));
    }
    return false;
  });

  return (
    <>
      <Module superModule={superModule} module={module} digibook={digibook} />
      <span className="pbb-drawer__intro">{t('drawer.toc.title')}</span>
      <SimpleBar className="pbb-drawer__contentwrap">
        <div className="pbb-drawer__content pbb-toc">
          <ul className="pbb-list pbb-toc__list-level-1">
            {tocnodeIds &&
              tocnodeIds.map(nodeId => {
                const node = getNodeInfo(nodeId);
                if (node.id === 'general') node.title = t('drawer.toc.general');

                const subnodes =
                  node.nodes && isNodeExpanded(nodeId)
                    ? node.nodes.map(subnodeId => {
                        const subNode = getNodeInfo(subnodeId);
                        return (
                          <TocItem
                            key={`tocitem_${subnodeId}`}
                            node={subNode}
                            expanded={isNodeExpanded(subnodeId)}
                            active={isNodeActive(subnodeId)}
                            level={2}
                            onNodeClicked={handleNodeClickedAndCloseDrawer}
                            showOptions={digibook.teacherFeaturesEnabled}
                            hasSharedSolutions={tocNodeIdsForSharedSolutions.includes(subnodeId)}
                            subnodes={
                              subNode.nodes && isNodeExpanded(subnodeId)
                                ? subNode.nodes.map(sId => (
                                    <TocItem
                                      key={`tocitem_${sId}`}
                                      node={getNodeInfo(sId)}
                                      expanded={false}
                                      level={3}
                                      active={isNodeActive(sId)}
                                      onNodeClicked={handleNodeClickedAndCloseDrawer}
                                      showOptions={digibook.teacherFeaturesEnabled}
                                      hasSharedSolutions={tocNodeIdsForSharedSolutions.includes(sId)}
                                    />
                                  ))
                                : null
                            }
                          />
                        );
                      })
                    : null;

                return (
                  <TocItem
                    key={`tocitem_${nodeId}`}
                    node={node}
                    expanded={isNodeExpanded(nodeId)}
                    active={isNodeActive(nodeId)}
                    subnodes={subnodes}
                    onNodeClicked={handleNodeClickedAndCloseDrawer}
                    showOptions={digibook.teacherFeaturesEnabled}
                    hasSharedSolutions={tocNodeIdsForSharedSolutions.includes(nodeId)}
                  />
                );
              })}
          </ul>
        </div>
      </SimpleBar>
      <Footer />
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  superModule: ownProps.superModuleId && getModuleById(state, ownProps.superModuleId),
  tocnodeIds: getChapterIdsOfCurrentModule(state),
  getNodeInfo: getTOCNodeById(state),
  activeNode: getActiveNodeToHighlight(state),
  expandedNodes: getExpandedNodes(state),
  tocNodeIdsForSharedSolutions: getTocNodeIdsForSharedSolutions(state),
  module: getModuleForDigibook(state),
});

const mapDispatchToProps = {
  onTocNodeClicked: tocNodeClicked,
};

Toc.propTypes = {
  tocnodeIds: arrayOf(string),
  getNodeInfo: func,
  activeNode: shape({ id: string.isRequired }),
  expandedNodes: arrayOf(shape({ id: string.isRequired })),
  onTocNodeClicked: func.isRequired,
  superModule: shape({
    subjectSlug: string,
    methodSlug: string,
    slug: string,
    id: string,
  }),
  module: shape({
    id: string.isRequired,
    slug: string.isRequired,
  }),
  digibook: shape({
    id: string,
    name: string,
    bookType: string,
    niceName: string,
    cover: string,
    hasLicence: string,
    teacherFeaturesEnabled: bool,
  }).isRequired,
  tocNodeIdsForSharedSolutions: arrayOf(string),
};

export const ConnectedToc = connect(mapStateToProps, mapDispatchToProps)(Toc);

export default ConnectedToc;
