import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useTextAnnotationTranslations() {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      annotationTool: {
        placeHolder: t('annotationTool.placeHolder'),
        delete: {
          'delete-modal': {
            title: t('annotationTool.delete.delete-modal.title'),
            message: t('annotationTool.delete.delete-modal.message'),
          },
        },
        link: {
          'link-modal': {
            url: {
              label: t('annotationTool.link.link-modal.url.label'),
              placeholder: t('annotationTool.link.link-modal.url.placeholder'),
            },
            validation: {
              url: t('annotationTool.link.link-modal.validation.url'),
            },
            text: {
              label: t('annotationTool.link.link-modal.text.label'),
              placeholder: t('annotationTool.link.link-modal.text.placeholder'),
            },
            delete: t('annotationTool.link.link-modal.delete'),
            cancel: t('annotationTool.link.link-modal.cancel'),
            add: t('annotationTool.link.link-modal.add'),
          },
        },
        buttons: {
          tooltip: {
            align: t('annotationTool.buttons.tooltip.align'),
            backgroundcolor: t('annotationTool.buttons.tooltip.backgroundcolor'),
            bold: t('annotationTool.buttons.tooltip.bold'),
            center: t('annotationTool.buttons.tooltip.center'),
            delete: t('annotationTool.buttons.tooltip.delete'),
            fontcolor: t('annotationTool.buttons.tooltip.fontcolor'),
            fontsize: t('annotationTool.buttons.tooltip.fontsize'),
            fontsizedown: t('annotationTool.buttons.tooltip.fontsizedown'),
            fontsizeup: t('annotationTool.buttons.tooltip.fontsizeup'),
            highlight: t('annotationTool.buttons.tooltip.highlight'),
            italic: t('annotationTool.buttons.tooltip.italic'),
            justify: t('annotationTool.buttons.tooltip.justify'),
            left: t('annotationTool.buttons.tooltip.left'),
            lineHeight: t('annotationTool.buttons.tooltip.lineHeight'),
            link: t('annotationTool.buttons.tooltip.link'),
            ol: t('annotationTool.buttons.tooltip.ol'),
            right: t('annotationTool.buttons.tooltip.right'),
            strikethrough: t('annotationTool.buttons.tooltip.strikethrough'),
            subscript: t('annotationTool.buttons.tooltip.subscript'),
            superscript: t('annotationTool.buttons.tooltip.superscript'),
            symbols: t('annotationTool.buttons.tooltip.symbols'),
            ul: t('annotationTool.buttons.tooltip.ul'),
            underline: t('annotationTool.buttons.tooltip.underline'),
            fontFamily: t('annotationTool.buttons.tooltip.fontFamily'),
          },
        },
        font: {
          sansSerif: t('annotationTool.font.sansSerif'),
          serif: t('annotationTool.font.serif'),
          monospace: t('annotationTool.font.monospace'),
          handwritten: t('annotationTool.font.handwritten'),
        },
      },
      actions: {
        cancel: t('actions.cancel'),
        deleteAll: t('actions.deleteAll'),
      },
      colors: {
        green: t('colors.green'),
        yellow: t('colors.yellow'),
        orange: t('colors.orange'),
        red: t('colors.red'),
        blue: t('colors.blue'),
        purple: t('colors.purple'),
        black: t('colors.black'),
        grey: t('colors.grey'),
        transparent: t('colors.transparent'),
        white: t('colors.white'),
      },
      colorPicker: {
        customSlot: {
          title: t('colorPicker.customSlot.title'),
        },
        buttons: {
          cancel: t('colorPicker.buttons.cancel'),
          confirm: t('colorPicker.buttons.confirm'),
          edit: t('colorPicker.buttons.edit'),
        },
      },
    }),
    [t],
  );
}
