import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { shape, string } from 'prop-types';
import ModuleLogo from '../moduleLogo';
import DrawerButtons from '../sidebar/items/drawerButtons';
import { getModuleForDigibook, getTeacherFeaturesEnabledFor } from '../../../../selectors/digibooks';
import { UserSettingsContext } from '../../context/user-settings-context';
import AnchorPosition from '../../../../enums/anchorposition';
import Drawer from '../sidebar/drawer';

function TopBar({ superModuleId, digibook }) {
  const module = useSelector(getModuleForDigibook);
  const teacherFeaturesEnabled = useSelector(getTeacherFeaturesEnabledFor);

  const { activeDrawer, isSidebarOpen } = useContext(UserSettingsContext);

  return (
    <div className={`pbb-panel pbb-panel--${AnchorPosition.TOP}`} data-testid="top-bar">
      <div className="pbb-top-bar">
        <ModuleLogo logo={module?.styling?.logo} />
        <DrawerButtons />
      </div>
      <Drawer isOpen={isSidebarOpen} activeDrawer={activeDrawer} superModuleId={superModuleId} digibook={digibook} teacherFeaturesEnabled={teacherFeaturesEnabled} />
    </div>
  );
}

TopBar.propTypes = {
  superModuleId: string,
  digibook: shape({
    id: string.isRequired,
    module: string.isRequired,
  }).isRequired,
};

export default TopBar;
