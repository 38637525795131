
const COVER = 0
const FIRST_PAGE = 1

/**
 * @description Given a pagenumber and the total amount of pages, calculate the corresponding spread.
 * @param {number} totalPages
 * @param {number} page
 *
 * @returns {[number?, number?]}
 */
export function calculateSpreadForPage(totalPages, page) {
  if (page === undefined) return [];

  const backCover = totalPages + 1;
  if ([COVER, FIRST_PAGE, backCover].includes(page)) return [page];

  if (page % 2 === 0) {
    if (page === totalPages) return [page];
    return [page, page + 1];
  }

  return [page - 1, page];
}
