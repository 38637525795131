import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ActionMenu, MenuGroup, MenuItem } from '@pelckmans/business-components/components/action-menu';
import { Button } from '@pelckmans/business-components/components/button';
import AnnotationSetIcon from '@pelckmans/business-components/icons/AnnotationSet';
import EyeStrikethroughInSquareIcon from '@pelckmans/business-components/icons/EyeStrikethroughInSquare';
import EyeInSquareIcon from '@pelckmans/business-components/icons/EyeInSquare';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { openAnnotationSetsManagementModal } from '../../../../../actions/dialog';
import { getActiveAnnotationSet, getAnnotationSets } from '../../../../../selectors/annotationSets';
import { setActiveAnnotationSet } from '../../../../../actions/annotationSets';
import { UserSettingsContext } from '../../../context/user-settings-context';
import { setCurrentTool } from '../../../../../actions/tools';
import ToolsEnum from '../../../../../enums/tools';
import { MobileSizeContext } from '../../../context/MobileSizeContext';
import Flyout from '../../bottomBar/components/Flyout';

const { POINTER } = ToolsEnum;

export default function AnnotationSetsButton() {
  const { t } = useTranslation();
  const annotationSets = useSelector(getAnnotationSets);
  const activeAnnotationSet = useSelector(getActiveAnnotationSet);
  const { showMarkings, setShowMarkings } = useContext(UserSettingsContext);
  const { isMobileWidth, isMobileHeight } = useContext(MobileSizeContext);

  const dispatch = useDispatch();

  const [isFlyoutVisible, setVisibilityOfFlyout] = useState(false);

  const handleClick = () => {
    setVisibilityOfFlyout(false);
    dispatch(openAnnotationSetsManagementModal());
  };

  const onItemClick = annotationSet => {
    dispatch(setActiveAnnotationSet(annotationSet));
  };

  const toggleMarkings = () => {
    dispatch(setCurrentTool(POINTER));
    setShowMarkings(!showMarkings);
  };

  if (isMobileWidth && isMobileHeight) {
    return (
      <>
        <button type="button" title={t('dock.buttons.annotationSets.tooltip')} onClick={() => setVisibilityOfFlyout(!isFlyoutVisible)}>
          <AnnotationSetIcon data-testid="annotation-set-icon" />
        </button>
        {createPortal(
          <Flyout visible={isFlyoutVisible} setVisibility={setVisibilityOfFlyout} className="annotation-sets-menu">
            <>
              <div className="annotation-sets-menu__header">{t('dock.actionMenu.annotationSets.title')}</div>
              <div data-testid="annotation-sets">
                {annotationSets.map(annotationSet => (
                  <button
                    key={annotationSet.id}
                    type="button"
                    onClick={() => onItemClick(annotationSet)}
                    className={classNames('annotation-set', { active: activeAnnotationSet.id === annotationSet.id })}
                  >
                    <AnnotationSetIcon />
                    <span>{annotationSet.name}</span>
                  </button>
                ))}
              </div>
              <div>
                <Button size="small" onClick={handleClick} title={t('dock.actionMenu.annotationSets.managementButton')}>
                  {t('dock.actionMenu.annotationSets.managementButton')}
                </Button>
              </div>
              <button type="button" onClick={toggleMarkings} className="annotation-sets-menu__toggle-markings">
                {showMarkings ? (
                  <>
                    <EyeStrikethroughInSquareIcon />
                    {t('dock.actionMenu.annotationSets.hide')}
                  </>
                ) : (
                  <>
                    <EyeInSquareIcon />
                    {t('dock.actionMenu.annotationSets.show')}
                  </>
                )}
              </button>
            </>
          </Flyout>,
          document.body,
        )}
      </>
    );
  }

  return (
    <ActionMenu
      title="Menu"
      menuClassName="dock-flyout-menu annotation-sets-menu"
      action={
        <button type="button" title={t('dock.buttons.annotationSets.tooltip')}>
          <AnnotationSetIcon data-testid="annotation-set-icon" />
        </button>
      }
      setDownOverflow
      boundingBoxPadding="10"
      portal
      align={isMobileWidth ? 'center' : 'start'}
    >
      <MenuItem data-testid="menu-title" className="annotation-sets-menu__header">
        {t('dock.actionMenu.annotationSets.title')}
      </MenuItem>
      <MenuGroup takeOverflow>
        {annotationSets.map(annotationSet => (
          <React.Fragment key={annotationSet.id}>
            <MenuItem
              className={classNames('dock-flyout-menu__item', { 'dock-flyout-menu__item--active': activeAnnotationSet.id === annotationSet.id })}
              onClick={() => onItemClick(annotationSet)}
              title={annotationSet.name}
            >
              <AnnotationSetIcon />
              {annotationSet.name}
            </MenuItem>
          </React.Fragment>
        ))}
      </MenuGroup>
      <MenuItem className="annotation-sets-menu__button" title="">
        <Button size="small" onClick={handleClick} title={t('dock.actionMenu.annotationSets.managementButton')}>
          {t('dock.actionMenu.annotationSets.managementButton')}
        </Button>
      </MenuItem>
    </ActionMenu>
  );
}
