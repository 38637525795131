import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { string, func, bool, arrayOf } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Tools from '../../../../../enums/tools';
import ToolOptions from './options';
import { setColor, setSize } from '../../../../../actions/tools';
import { getTools } from '../../../../../selectors/tools';
import { TOOL_ICON_MAP } from './tool-icon-map';
import { rgbaToRgb } from '../../../../../utils/colors';

const toolsWithColorIndicator = [Tools.PENCIL, Tools.MARKER, Tools.TEXT_MARKER];

function ToolSet({ isOpen = false, currentTool, onToolClick, tools, renderWithOptions = true, tooltip, disabled = false, menuClassName }) {
  const [lastSelectedTool, setLastSelectedTool] = useState(tools.includes(currentTool) ? currentTool : tools[0]);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const allTools = useSelector(getTools);
  const currentOptions = allTools[currentTool];
  const isActive = currentTool === lastSelectedTool;
  const toolRef = useRef(null);

  useEffect(() => {
    if (!toolsWithColorIndicator.some(x => tools.includes(x))) return;

    if (isActive && currentOptions?.color?.color) {
      toolRef.current.style.setProperty('--accent-color', rgbaToRgb(currentOptions.color.color, 0.75));
    }

    if (!tools.includes(currentTool)) {
      toolRef.current.style.removeProperty('--accent-color');
    }
  }, [currentOptions?.color?.color, isActive, isOpen, currentTool, tools]);

  return (
    <div className="pbb-tool-wrapper">
      <button
        type="button"
        id={lastSelectedTool}
        data-testid={lastSelectedTool}
        onClick={() => onToolClick(lastSelectedTool)}
        title={tools.includes(currentTool) ? t(`${currentTool}Tool.tooltip`) : tooltip}
        className={classNames('pbb-sidebar__button', {
          'pbb-sidebar__button--selected': isOpen,
          'pbb-sidebar__button--active': isActive,
        })}
        disabled={disabled}
        ref={toolRef}
      >
        {TOOL_ICON_MAP[lastSelectedTool]({ className: 'pbb-sidebar__icon' })}
      </button>
      {isOpen && (
        <div
          className={classNames('pbb-sidebar__menu', {
            [menuClassName]: Boolean(menuClassName),
          })}
        >
          <div className="pbb-menu__tools">
            {tools.map(tool => (
              <button
                key={tool}
                type="button"
                title={t(`${tool}Tool.tooltip`)}
                onClick={() => {
                  onToolClick(tool);
                  setLastSelectedTool(tool);
                }}
                className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active': currentTool === tool })}
              >
                {TOOL_ICON_MAP[tool]({ className: 'pbb-sidebar__icon' })}
              </button>
            ))}
          </div>
          {currentTool && renderWithOptions && (
            <>
              <div className="divider-line" />
              <ToolOptions
                inline
                variant={currentTool}
                onColorClick={color => dispatch(setColor(color))}
                onSizeClick={size => dispatch(setSize(size))}
                currentOptions={allTools[currentTool]}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}

ToolSet.propTypes = {
  isOpen: bool,
  currentTool: string,
  onToolClick: func.isRequired,
  tools: arrayOf(string).isRequired,
  renderWithOptions: bool,
  tooltip: string.isRequired,
  disabled: bool,
  menuClassName: string,
};

export default ToolSet;
