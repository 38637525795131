import React, { useContext, useLayoutEffect } from 'react';
import { arrayOf, bool, number, shape } from 'prop-types';
import { MobileSizeContext } from '../../context/MobileSizeContext';
import ToggleMarkingsButton from './items/ToggleMarkingsButton';
import SolutionsPanel from './items/solutions';
import ZoomButtons from './items/zoom/index';
import ContentCounterContext from '../../context/ContentCounterContext';

function useCountItems(showSolutionItems) {
  const { increaseDividersCounterBy, increaseItemsCounterBy } = useContext(ContentCounterContext);

  useLayoutEffect(() => {
    if (showSolutionItems) {
      increaseDividersCounterBy(1);
    }
  }, [increaseDividersCounterBy, showSolutionItems]);

  // counter for toggle markings buttons
  useLayoutEffect(() => {
    increaseDividersCounterBy(1);
    increaseItemsCounterBy(1);
  }, [increaseDividersCounterBy, increaseItemsCounterBy]);
}

const StudentDock = ({ digibook, showSolutionItems }) => {
  const { isMobileWidth } = useContext(MobileSizeContext);
  useCountItems();

  return (
    <>
      <li>
        <ToggleMarkingsButton />
      </li>
      {!isMobileWidth && <li className="pbb-dock__wrapper__divider" />}
      {showSolutionItems && (
        <>
          <li>
            <SolutionsPanel digibook={digibook} isMobileWidth={isMobileWidth} />
          </li>
          {!isMobileWidth && <li className="pbb-dock__wrapper__divider" />}
        </>
      )}
      <li>
        <ZoomButtons isMobileWidth={isMobileWidth} />
      </li>
    </>
  );
};

StudentDock.propTypes = {
  digibook: shape({
    allowedRanges: arrayOf(
      shape({
        from: number.isRequired,
        to: number.isRequired,
      }),
    ),
    totalPages: number.isRequired,
  }),
  showSolutionItems: bool.isRequired,
};

export default StudentDock;
