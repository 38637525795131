import React, { useContext, useLayoutEffect } from 'react';
import BookIcon from '@pelckmans/business-components/icons/Book';
import MediaIcon from '@pelckmans/business-components/icons/Media';
import NoteIcon from '@pelckmans/business-components/icons/Note';
import SlideSetIcon from '@pelckmans/business-components/icons/SlideSet';
import WhitepageIcon from '@pelckmans/business-components/icons/Whitepage';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DrawerMode from '../../../../../enums/drawermode';
import { getCurrentPageNumber } from '../../../../../selectors/navigation';
import { getIsSlideSetsAccessEnabled, getIsStudentWithoutSharedSlideSets } from '../../../../../selectors/slideSets';
import { isTeacherAlike } from '../../../../../selectors/user';
import ContentCounterContext from '../../../context/ContentCounterContext';
import { MobileSizeContext } from '../../../context/MobileSizeContext';
import { UserSettingsContext } from '../../../context/user-settings-context';

function useContentCounter({ isTeacher, isSlideSetsAccessEnabled, isStudentWithoutSharedSlideSets }) {
  const { increaseItemsCounterBy } = useContext(ContentCounterContext);

  useLayoutEffect(() => {
    if (isTeacher) {
      increaseItemsCounterBy(1);
    }
  }, [increaseItemsCounterBy, isTeacher]);

  useLayoutEffect(() => {
    if (isSlideSetsAccessEnabled && !isStudentWithoutSharedSlideSets) {
      increaseItemsCounterBy(1);
    }
  }, [increaseItemsCounterBy, isSlideSetsAccessEnabled, isStudentWithoutSharedSlideSets]);

  // material, toc, notes
  useLayoutEffect(() => {
    increaseItemsCounterBy(3);
  }, [increaseItemsCounterBy]);
}

export const DrawerButtons = () => {
  const isSlideSetsAccessEnabled = useSelector(getIsSlideSetsAccessEnabled);
  const isStudentWithoutSharedSlideSets = useSelector(getIsStudentWithoutSharedSlideSets);
  const isTeacher = useSelector(isTeacherAlike);
  const currentPageNumber = useSelector(getCurrentPageNumber);

  const { isMobileHeight } = useContext(MobileSizeContext);
  const { activeDrawer, setActiveDrawer, isSidebarOpen, setSidebarOpened } = useContext(UserSettingsContext);

  function isOpened(drawer) {
    return isSidebarOpen && activeDrawer === drawer;
  }

  const [t] = useTranslation();

  useContentCounter({ isTeacher, isSlideSetsAccessEnabled, isStudentWithoutSharedSlideSets });

  function handleClick(active) {
    if (active !== activeDrawer) {
      setActiveDrawer(active);
      if (!isSidebarOpen) setSidebarOpened(true);
    } else {
      setSidebarOpened(!isSidebarOpen);
    }
  }

  return (
    <div className="pbb-sidebar__drawer">
      {isTeacher && (
        <button
          className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active pbb-sidebar__button--selected': isOpened(DrawerMode.WHITEPAGES) })}
          type="button"
          onClick={() => handleClick(DrawerMode.WHITEPAGES)}
          title={t('drawer.buttons.whitepages.tooltip')}
        >
          <WhitepageIcon className="pbb-sidebar__icon" />
        </button>
      )}
      <button
        className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active pbb-sidebar__button--selected': isOpened(DrawerMode.NOTES) })}
        type="button"
        onClick={() => handleClick(DrawerMode.NOTES)}
        title={t('drawer.buttons.notes.tooltip')}
      >
        <NoteIcon className="pbb-sidebar__icon" />
      </button>
      <button
        className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active pbb-sidebar__button--selected': isOpened(DrawerMode.MATERIAL) })}
        type="button"
        onClick={() => handleClick(DrawerMode.MATERIAL)}
        title={t('drawer.buttons.material.tooltip')}
      >
        <MediaIcon className="pbb-sidebar__icon" />
      </button>
      {isSlideSetsAccessEnabled && !isStudentWithoutSharedSlideSets && (
        <button
          className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active pbb-sidebar__button--selected': isOpened(DrawerMode.SLIDE_SETS) })}
          type="button"
          onClick={() => handleClick(DrawerMode.SLIDE_SETS)}
          title={t('drawer.buttons.slideSets.tooltip')}
        >
          <SlideSetIcon className="pbb-sidebar__icon" />
        </button>
      )}
      <button
        className={classNames('pbb-sidebar__button', 'pbb-sidebar__button-toc', { 'pbb-sidebar__button--active pbb-sidebar__button--selected': isOpened(DrawerMode.TOC) })}
        type="button"
        onClick={() => handleClick(DrawerMode.TOC)}
        title={t('drawer.buttons.toc.tooltip')}
      >
        <BookIcon className="pbb-sidebar__icon book-icon" />
        <div className="page-number">
          {isMobileHeight && t('drawer.buttons.toc.pageAbbreviation')}
          {currentPageNumber}
        </div>
      </button>
    </div>
  );
};

export default DrawerButtons;
