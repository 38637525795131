import React from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import PortaalLogo from '../../../../../../components/portaal-logo';
import { STUDIO_GROUP } from '../../../../../../constants/constants';

export const OpenInPButton = ({ onClick, url }) => {
  const [t] = useTranslation();
  const showLogo = ['SO', 'ES'].includes(STUDIO_GROUP);

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a className="pbb-slider-back__open" onClick={onClick} title={t('sideBar.slidingButton.openInPButton.tooltip')} href={url} target="_blank">
      <span>{t('slider.open')}</span>
      {showLogo && <PortaalLogo />}
    </a>
  );
};

OpenInPButton.propTypes = {
  onClick: func.isRequired,
  url: string.isRequired,
};

export default OpenInPButton;
