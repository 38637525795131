import CloseIcon from '@pelckmans/business-components/icons/Close';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function Flyout({ visible, setVisibility, className = undefined, children }) {
  const { t } = useTranslation();

  return (
    <div className={classNames('flyout', 'flyout-from-bottom', { hidden: !visible }, className)} data-testid="flyout" aria-hidden={!visible}>
      <button type="button" className="flyout__close" onClick={() => setVisibility(false)} title={t('options.close')}>
        <CloseIcon />
      </button>
      <div className="flyout__content">{children}</div>
    </div>
  );
}

Flyout.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisibility: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Flyout;
