import { DOCEO_OVERVIEW_URL, DOCEO_TEMPLATE_URL } from '../constants/constants';

const DEFAULT_PROVIDER = 'pelckmans';
/**
 * @param {Object} slideSetInfo The slide set info with its id, versionId and state
 * @param {boolean} [isEditMode] Passing a boolean allows you to navigate to the overview/edit page, undefined goes to presentation mode
 * @param {string} [eventSubLocation] Allow doceo to handle the navigation event since we don't have slide/link information
 */
export const buildDoceoUrl = ({ id, versionId, state, nodeId, moduleId }, isEditMode, eventSubLocation) => {
  const provider = localStorage.getItem('p-provider') || DEFAULT_PROVIDER;

  const query = new URLSearchParams();

  if (id === 'new') {
    if (nodeId) query.set('nodeId', nodeId);
    if (moduleId) query.set('moduleId', moduleId);
    if (isEditMode) query.set('edit', isEditMode);
    return `${DOCEO_TEMPLATE_URL.replace(':provider', provider).replace(':id', id)}?${query}`;
  }

  if (isEditMode) query.set('edit', isEditMode);
  if (eventSubLocation) query.set('origin', eventSubLocation);
  if (state !== 'PUBLISHED' && versionId) query.set('versionId', versionId);

  const baseUrl = isEditMode === undefined ? DOCEO_TEMPLATE_URL : DOCEO_OVERVIEW_URL;
  return `${baseUrl.replace(':provider', provider).replace(':id', id)}?${query}`;
};
