import React, { useContext, useMemo, useState } from 'react';
import { Jodit } from 'jodit';

import { FONT_SIZES, LINE_HEIGHTS, SPECIAL_CHARACTERS } from '@pelckmans/business-components/components/text-annotations/constants';
import JoditEditor from '@pelckmans/business-components/components/text-annotations/jodit-react';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CustomColorContext from '../../../../../contexts/CustomColorContext';

const DEFAULT_TEXT = '<p><br></p>';

export default function NoteEditor({ note, onChange }) {
  const { t } = useTranslation();
  const { customColors, saveCustomColor } = useContext(CustomColorContext);
  const [initialNoteText] = useState(note.note || DEFAULT_TEXT);

  const config = useMemo(
    () => ({
      buttons: [
        'fontFamily',
        'fontsizedown',
        'fontsize',
        'fontsizeup',
        'fontcolor',
        'fontbackgroundcolor',
        '|',
        'bold',
        'italic',
        'strikethrough',
        'underline',
        '|',
        'lineHeight',
        'align',
        'ol',
        'ul',
        '|',
        'symbols',
        '|',
        'superscript',
        'subscript',
        'link',
      ],
      toolbarAdaptive: false,
      width: '100%',
      height: '100%',
      statusbar: false, // Hides bottom status bar
      toolbarSticky: false, // Makes sure toobar keeps it's original position when scrolling out of view
      toolbarButtonSize: 'large',
      defaultFontSizePoints: '',
      controls: {
        align: {
          list: {
            left: 'Align Left',
            center: 'Align Center',
            right: 'Align Right',
            justify: 'Align Justify',
          },
        },
        fontsize: {
          list: Jodit.atom(FONT_SIZES),
          component: 'select',
          isDisabled: (_, button) => FONT_SIZES.indexOf(Number(button.state.value)) === -1,
        },
        lineHeight: {
          list: Jodit.atom(LINE_HEIGHTS),
        },
        ul: { list: undefined },
        ol: { list: undefined },
      },
      askBeforePasteHTML: false,
      defaultActionOnPaste: 'insert_only_text',
      allowResizeX: false,
      allowResizeY: false,
      usePopupForSpecialCharacters: true,
      specialCharacters: SPECIAL_CHARACTERS,
      advancedColorPickerTranslations: {
        colorPicker: {
          customSlot: {
            title: t('colorPicker.customSlot.title'),
          },
          buttons: {
            edit: t('colorPicker.buttons.edit'),
            cancel: t('colorPicker.buttons.cancel'),
            confirm: t('colorPicker.buttons.confirm'),
          },
        },
        colors: {
          green: t('colors.green'),
          yellow: t('colors.yellow'),
          orange: t('colors.orange'),
          red: t('colors.red'),
          blue: t('colors.blue'),
          purple: t('colors.purple'),
          black: t('colors.black'),
          grey: t('colors.grey'),
          transparent: t('colors.transparent'),
        },
      },
      colorPickerTranslations: {
        green: t('colors.green'),
        yellow: t('colors.yellow'),
        orange: t('colors.orange'),
        red: t('colors.red'),
        blue: t('colors.blue'),
        purple: t('colors.purple'),
        black: t('colors.black'),
        grey: t('colors.grey'),
        transparent: t('colors.transparent'),
      },
      events: {
        afterInit: ed => {
          ed.focus();
        },
      },
      t,
    }),
    [t],
  );

  return <JoditEditor id={note.id} config={config} onChange={onChange} value={initialNoteText} customColors={customColors} saveCustomColor={saveCustomColor} />;
}

NoteEditor.propTypes = {
  note: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
