const featureToggle = {
  NOTES: 'NOTES',
  WHITEPAGES: 'WHITEPAGES',
  SHARING_MATERIAL: 'SHARING_MATERIAL',
  MULTI_PAGE_PRINTS_WITHOUT_ANSWERS: 'MULTI_PAGE_PRINTS_WITHOUT_ANSWERS',
  MATH_TOOLS: 'MATH_TOOLS',
  BOOKWIDGET_USER_MATERIAL: 'BOOKWIDGET_USER_MATERIAL',
  SLIDE_SETS: 'SLIDE_SETS',
  SHARE_SLIDE_SET: 'SHARE_SLIDE_SET',
  CODEX_SCROLL_VIEW: 'CODEX_SCROLL_VIEW',
  BOOKMARKS: 'BOOKMARKS',
};

export default featureToggle;
