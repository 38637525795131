import PropTypes from 'prop-types';
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import api from '../services/api';
import { colorObjects } from '../enums/colors';

const initialColors = {
  pencil: Object.seal(new Array(8).fill(undefined)),
  marker: Object.seal(new Array(8).fill(undefined)),
  text_marker: Object.seal(new Array(8).fill(undefined)),
  font_color: Object.seal(new Array(8).fill(undefined)),
  background_color: Object.seal(new Array(8).fill(undefined)),
};

const CustomColorContext = createContext({
  customColors: {
    pencil: [],
    marker: [],
    text_marker: [],
    font_color: [],
    background_color: [],
  },
  saveCustomColor: () => {},
  allColors: [],
});

export default CustomColorContext;

async function fetchCustomColors() {
  const response = await api.get('/studio/user/custom-colors');

  return response.data.data;
}

async function replaceCustomColor({ color, order }, tool) {
  return api.put(`/studio/user/custom-colors/${tool}`, { color, order });
}

async function createCustomColor({ color, order }, tool) {
  return api.post(`/studio/user/custom-colors/${tool}`, { color, order });
}

export const CustomColorProvider = ({ children }) => {
  const [customColors, setCustomColors] = useState(initialColors);

  const getCustomColors = useCallback(async () => {
    const colorsData = await fetchCustomColors();
    const updatedColors = { ...initialColors };

    Object.entries(updatedColors).forEach(([category, defaultColors]) => {
      const colors = colorsData[category] || [];

      const sealedCategoryColors = [...defaultColors];

      colors.forEach(color => {
        const rgbWithSpaces = color.color.split(',').join(', ');
        sealedCategoryColors[color.order] = { ...color, color: rgbWithSpaces };
      });

      updatedColors[category] = Object.seal(sealedCategoryColors);
    });

    setCustomColors(updatedColors);

    return updatedColors;
  }, []);

  const initialFetchedRef = useRef(false);
  useEffect(() => {
    if (!initialFetchedRef.current) {
      getCustomColors();
      initialFetchedRef.current = true;
    }
  }, [getCustomColors]);

  async function saveCustomColor(newCustomColor, tool) {
    const { order } = newCustomColor;

    const shouldCreate = customColors[tool][order] === undefined;

    if (shouldCreate) {
      await createCustomColor(newCustomColor, tool);
    } else {
      await replaceCustomColor(newCustomColor, tool);
    }

    const newColors = await getCustomColors();

    return newColors[tool][order];
  }

  const allColors = useMemo(() => {
    const definedCustomColors = Object.values(customColors)
      .flat()
      .filter(Boolean);

    return Object.values(colorObjects).concat(...definedCustomColors);
  }, [customColors]);

  return <CustomColorContext.Provider value={{ customColors, saveCustomColor, allColors }}>{children}</CustomColorContext.Provider>;
};

CustomColorProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
