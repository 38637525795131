import debounce from 'lodash/debounce';
import { MenuItem } from '@pelckmans/business-components/components/action-menu';
import { OptionsMenu } from '@pelckmans/business-components/components/options-menu';
import BinIcon from '@pelckmans/business-components/icons/Bin';
import InfoIcon from '@pelckmans/business-components/icons/Info';
import NoteIcon from '@pelckmans/business-components/icons/Note';
import { string } from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';
import { closePopUpNote as closePopUpNoteModal, movePopUpNoteToFront, openNoteOptionsModal } from '../../../../../actions/dialog';
import { updateNote } from '../../../../../actions/notes';
import AnchorPosition from '../../../../../enums/anchorposition';
import { getOpenDialogs, getPopUpNoteCount } from '../../../../../selectors/dialogs';
import { getNoteById } from '../../../../../selectors/notes';
import { formatDate } from '../../../../../utils/intl';
import { UserSettingsContext } from '../../../context/user-settings-context';
import DialogHeader from '../common/header';
import { getInitialPopUpPosition } from '../common/utils';
import { getManualResizeConfig } from '../utils';
import NoteEditor from './note-editor';

export default function PopUpNote({ noteId, tocNodeId }) {
  const { t } = useTranslation();

  const { sidebarAnchor } = useContext(UserSettingsContext);
  const dispatch = useDispatch();
  const { isInFront } = useSelector(getOpenDialogs).find(d => d.id === noteId);
  const note = useSelector(state => getNoteById(state, noteId));
  const popUpCountOffset = 32 * (useSelector(getPopUpNoteCount) - 1);

  const isPositionedLeft = sidebarAnchor === AnchorPosition.RIGHT;
  const initialPos = getInitialPopUpPosition(isPositionedLeft);

  initialPos.x = isPositionedLeft ? initialPos.x + popUpCountOffset : initialPos.x - popUpCountOffset;

  const [{ x, y }, setPosition] = useState({ x: initialPos.x, y: initialPos.y });
  const [{ width, height }, setSize] = useState({ width: initialPos.width, height: initialPos.height });

  const handlCloseNote = () => dispatch(closePopUpNoteModal(noteId));

  const moveToFront = () => dispatch(movePopUpNoteToFront(noteId));

  const openNoteOptions = activeTab => dispatch(openNoteOptionsModal(tocNodeId, noteId, activeTab));

  const handleSave = useMemo(
    () =>
      debounce(newHTML => {
        dispatch(
          updateNote({
            id: noteId,
            nodeId: tocNodeId,
            note: newHTML || '<p><br/></p>',
          }),
        );
      }, 500),
    [noteId, tocNodeId, dispatch],
  );

  const target = document.getElementById('drawer-options-menu-container');

  return (
    <Rnd
      className="pbb-modal pbb-modal--media manual-dialog"
      position={{ x, y }}
      minWidth={300}
      minHeight={120}
      key={noteId}
      onDragStop={(_e, d) => setPosition({ x: d.x, y: d.y })}
      bounds="parent"
      enableResizing={getManualResizeConfig()}
      dragHandleClassName="draggable"
      size={{ width, height }}
      onResizeStart={() => document.querySelector('body').classList.add('react-draggable-transparent-selection')}
      onResizeStop={(_e, _d, ref) => {
        document.querySelector('body').classList.remove('react-draggable-transparent-selection');
        setSize({ width: ref.offsetWidth, height: ref.offsetHeight });
      }}
      resizeHandleClasses={{ bottomRight: 'resizeHandle' }}
      style={{ zIndex: isInFront ? 99 : 1 }}
      onDragStart={moveToFront}
      onClick={moveToFront}
      role="dialog"
    >
      <div className="pbb-modal__dialog popup-note">
        <div className="pbb-modal__content">
          <DialogHeader
            draggable
            icon={<NoteIcon />}
            title={`${t('notes.note')} ${formatDate(new Date(note.updatedAt))}`}
            close={handlCloseNote}
            action={
              <OptionsMenu title={t('buttons.optionsButton.tooltip')} viewScroll="close" portal={{ target }}>
                <MenuItem onClick={() => openNoteOptions('info')}>
                  <InfoIcon />
                  <span>{t('tabs.info.title')}</span>
                </MenuItem>
                <MenuItem onClick={() => openNoteOptions('delete')}>
                  <BinIcon />
                  <span>{t('tabs.delete')}</span>
                </MenuItem>
              </OptionsMenu>
            }
          />
          <div className="pbb-modal__body">
            <NoteEditor onChange={handleSave} note={note} />
          </div>
          <footer className="pbb-modal__footer draggable">
            <span className="pbb-modal__drag" title={t('mediaDialog.footer.buttons.move.tooltip')}>
              <i className="icon-bb-modal-drag" />
            </span>
            <span className="pbb-modal__resize" data-testid="modal-resize">
              <i className="icon-bb-modal-resize" />
            </span>
          </footer>
        </div>
      </div>
    </Rnd>
  );
}

PopUpNote.propTypes = {
  noteId: string.isRequired,
  tocNodeId: string.isRequired,
};
