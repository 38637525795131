import React, { useLayoutEffect } from 'react';
import DrawingPencilIcon from '@pelckmans/business-components/icons/DrawingPencil';
import { func } from 'prop-types';

const itemsCount = 1;

export default function ToolsButton({ calculateTotalWidth }) {
  useLayoutEffect(() => {
    calculateTotalWidth(itemsCount);

    return () => {
      calculateTotalWidth(0);
    };
  }, [calculateTotalWidth]);

  return (
    <button type="button">
      <DrawingPencilIcon />
    </button>
  );
}

ToolsButton.propTypes = {
  calculateTotalWidth: func.isRequired,
};
